<template>
  <div>
    <b-modal
      centered
      id="modal"
      title=""
      :hide-footer="true"
      content-class="signup-content"
      header-class="signup-header"
    >
      <h1 class="text-right mb-4">معلوماتي الشخصية</h1>

      <ValidationObserver v-slot="{ handleSubmit, invalid }">
        <b-form dir="rtl" @submit.prevent="handleSubmit(onSubmit)" ref="form">
          <ValidationProvider name="first_name" rules="required">
            <b-form-group
              slot-scope="{ errors }"
              class="text-right"
              id="first_name-group"
              label="الاسم الأول"
              label-for="first_name"
            >
              <b-form-input
                id="first_name"
                v-model="form.first_name"
                type="text"
                required
              ></b-form-input>
              <p>{{ errors[0] }}</p>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="last_name" rules="required">
            <b-form-group
              slot-scope="{ errors }"
              class="text-right"
              id="last_name-group"
              label="اسم العائلة"
              label-for="last_name"
            >
              <b-form-input
                id="last_name"
                v-model="form.last_name"
                type="text"
                required
              ></b-form-input>
              <p>{{ errors[0] }}</p>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="email" rules="required|email">
            <b-form-group
              slot-scope="{ errors }"
              class="text-right"
              id="email-group"
              label="البريد الالكتروني"
              label-for="email"
            >
              <b-form-input
                disabled
                id="email"
                v-model="form.email"
                type="email"
                required
              ></b-form-input>
              <p>{{ errors[0] }}</p>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="phone" rules="required|min:12|max:12">
            <b-form-group
              slot-scope="{ errors }"
              class="text-right"
              id="phone-group"
              label="جوال"
              label-for="phone"
            >
              <b-form-input
                dir="ltr"
                class="text-right"
                id="phone"
                max="12"
                v-model="form.phone"
                type="text"
                required
              ></b-form-input>
              <p>{{ errors[0] }}</p>
            </b-form-group>
          </ValidationProvider>
          <b-button
            type="submit"
            :disabled="invalid"
            class="text-center note-sub-btn"
            size="lg"
            block
            variant="success"
            >تحديث حسابي</b-button
          >
        </b-form>
      </ValidationObserver>
    </b-modal>

    <!-- <b-modal centered id="verify_phone" title="" @close="removeLoginQuery" :hide-footer=true content-class="signup-content" header-class="signup-header">
   <h1 class="text-right mb-4">سجل دخول برقم الجوال</h1>
   <p class="text-right float-right clearfix"><span class="width-70">تسجيل الدخول مسموح برقم جوال سعودي فقط
للتأكد من تواجد المستخدم في المملكة العربية
السعودية.</span></p>
   <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="form">
   <b-form @submit.prevent="handleSubmit(verifyPhone)">
      <ValidationProvider rules="required|min:9">
        <b-form-group class="font-sfns" slot-scope="{ errors }" id="phone-group" label-for="phone">
          <vue-tel-input v-on:country-changed="countryChanged" wrapperClasses="phone-wrapper" placeholder="9665122345678" defaultCountry="SA" id="phone" :onlyCountries="['SA']" required v-model="form.phone"></vue-tel-input>
          <p>{{ errors[0] }}</p>
        </b-form-group>
      </ValidationProvider>
     <b-button type="submit" :disabled="invalid" class="text-center note-sub-btn" size="lg" block variant="success">تسجيل الدخول</b-button>
  </b-form>
  </ValidationObserver>
</b-modal> -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { localize } from "vee-validate";
import ar from "vee-validate/dist/locale/ar.json";

localize({
  ar,
});
localize("ar");

export default {
//   name: "UpdateProfile",
  props: {},
  data() {
    return {
      form: {
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
      },
    };
  },
  mounted() {
    this.getUserProfile();
  },
  watch: {
    "form.phone"(newVal) {
      if (newVal.indexOf("+") != -1) {
        this.form.phone = newVal.replace("+", "");
      }
    },
  },
  computed: {
    status() {
      return this.$store.state.auth.status;
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  methods: {
    onSubmit() {
      delete this.form.email;
      let loader = this.$loading.show({ opacity: 0.9 });
      this.$store
        .dispatch("auth/updateUser", this.form)
        .then(() => {
          loader.hide();
          this.emptyForm();
          this.$bvModal.hide("modal");
        })
        .catch((err) => {
          loader.hide();
          if (err.data.error.error) {
            err.data.error.message.forEach((message) => {
              this.$toast.warning(message);
            });
          }
        });
    },
    getUserProfile() {
      if (
        this.form.first_name !== "" ||
        this.form.last_name !== "" ||
        this.form.phone !== ""
      ) {
        return;
      }
      let loader = this.$loading.show({ opacity: 0.9 });
      this.$store
        .dispatch("auth/getProfileUser")
        .then(({ data: { data } }) => {
          // console.log("here", data);
          const { first_name, last_name, email, phone } = data;
          this.form = {
            first_name,
            last_name,
            email,
            phone,
          };
          loader.hide();
          //   this.$toast.success("تم ارسال رمز التحقق");
        })
        .catch(() => {
          this.$toast.warning("Something went wrong!");
          loader.hide();
        });
    },
    emptyForm() {
      this.form.email =
        this.form.first_name =
        this.form.last_name =
        this.form.phone =
          "";
      this.$nextTick(() => {
        //this.$refs.form.reset()
      });
    },
  },
};
</script>
<style>
a {
  color: #333;
}
a:hover {
  color: #333;
}
</style>
