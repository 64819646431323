<template>
 <div>
        <update-profile ref="childs" />
  <b-container fluid class="user-content">
    <div class="user-profile">
      <b-row class="user-row">
        <b-col md="10"
          ><strong>{{ user.first_name + " " + user.last_name }}</strong></b-col
        >
        <b-col md="2">الاسم</b-col>
      </b-row>
      <hr />
      <b-row class="user-row">
        <b-col md="10"
          ><strong>{{ user.email }}</strong></b-col
        >
        <b-col md="2">البريد</b-col>
      </b-row>
      <hr />
      <b-row class="user-row">
       
        <b-col md="10 font-sfns"
          ><strong>{{ user.phone }}</strong></b-col
        >
        <b-col md="2">جوال</b-col>
      </b-row>
      <hr />
    </div>
    
    <div class="text-center">
      <b-button @click="handleModifyUser()" variant="primary" size="sm"> تعديل</b-button>
    </div>
    <!-- 
    <div class="user-profile mt-4">
      <b-row class="user-row">
        <b-col><b-icon scale="2" icon="check" class="primary-color__font"></b-icon></b-col>
        <b-col class="text-right">عربي</b-col>
      </b-row>
      <hr />
    </div> -->

    <div class="user-profile mt-4 cursor-pointer" @click="signOut">
      <b-row class="user-row">
        <b-col
          ><img
            src="@/assets/images/icons/signout.png"
            width="30px"
        /></b-col>
        <b-col class="text-right">تسجيل الخروج</b-col>
      </b-row>
      <hr />
    </div>
    <div class="user-profile mt-4 cursor-pointer" @click="deleteAccount">
      <b-row class="user-row">
        <b-col
          ><img
            src="@/assets/images/icons/user-delete.svg"
            width="30px"
        /></b-col>
        <b-col class="text-right">حذف الحساب</b-col>
      </b-row>
      <hr />
    </div>
    <div class="text-center fs-14 mtop-50">
       <router-link to="/privacy">
    <p>شروط الاستخدام و سياسة الخصوصية</p>
    </router-link>
      <p>
        <router-link to="/contact">اتصل بنا </router-link>
      </p>
   

    </div>
    <div class="text-center mtop-50">
      <img src="@/assets/logo_grey.svg" />
    </div>
  </b-container>
 </div>
</template>
<script>
import UpdateProfile from '../components/UpdateProfile.vue';
export default {
  title: "Favourite Plots",
  data() {
    return {
      isView: true
    };
  },
  components: {UpdateProfile},
  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    handleModifyUser() {
       this.$bvModal.show('modal')
       this.$refs.childs.getUserProfile()
    },
    signOut() {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userInfo");
      location.replace("/");
    },
    deleteAccount() {      
      if (confirm("هذا الإجراء لا يمكن التراجع عنه. يكمل؟")) {
        let loader = this.$loading.show({opacity: 0.9})

        this.$store.dispatch("auth/deleteAccount")
          .then(() => {
            loader.hide()
            localStorage.removeItem("accessToken");
            localStorage.removeItem("userInfo");
            location.replace("/");
          })
          .catch(() => {
            loader.hide()
          })
      }
    }
  },
  created() {},
  mounted() {},
};
</script>

<style lang="scss">
#map {
  width: 100%;
  height: 100vh;
}
</style>
